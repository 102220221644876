<template>
  <v-sheet
    @mouseenter="hovered=true"
    @mouseleave="hovered=false"
    :class="`${hovered ? 'grey lighten-5' : ''}`"
  >
    <v-divider v-if="!topItem" class="mb-0"/>
    <v-container fill-height fluid>
      <v-row align="center" class="px-3 d-flex flex-wrap">
        <v-col class="d-flex flex-grow-0 flex-shrink-1">
          <span class="text-h5 grey--text text--darken-2">{{ index }}</span>
        </v-col>
        <v-col class="pl-1 py-1 d-flex flex-grow-1 flex-shrink-0">
          <div class="mt-2 pb-2">
            <p class="mb-1">{{ data.comment }}</p>
            <p class="mb-0 text-body-2 primary--text">{{data.operator_name}}</p>
          </div>
        </v-col>
        <v-col :cols="chipColumnSize" class="pr-0 py-1 d-flex flex-grow-0 flex-shrink-0 justify-end">
          <v-tooltip
            bottom
            :open-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="mr-2"
                color="primary"
                label
                outlined
                v-bind="attrs"
                v-on="on"
              >{{data.comment_type}}</v-chip>
            </template>
            <span>Comment Type</span>
          </v-tooltip>
          <v-tooltip
            bottom
            :open-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                label
                outlined
                v-bind="attrs"
                v-on="on"
              >{{data.dlc | simpleDate}}</v-chip>
            </template>
            <span>Date Created</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    index: {
      type: Number,
    },
    topItem: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    CardSubtitle: () => import('./CardSubtitle.vue'),
  },
  data: () => ({
    hovered: false, // Is this comment item being hovered?
  }),
  computed: {
    chipColumnSize() {
      return (this.$vuetify.breakpoint.name !== 'xs') ? 0 : 12
    }
  },
  methods: {

  },
  filters: {
    /**
     * Formats an input date string into "YYYY-MM-DD, HH24:Mi:SS".
     */
    simpleDate: function (value) {
      return value.split('T').join(', ')
    }
  },
  created () {

  }
}
</script>